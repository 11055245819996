.button-bg,
.button-outline {
  color: $black;
  border-radius: 24px;
  padding: 12px 20px;
  font-weight: 700;
  line-height: 1;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  border: none;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
.button-bg {
  background: $header_bg;
}
.button-outline {
  background: $white;
  padding: 11px 19px;
  border: 1px solid $header_bg;
  box-sizing: border-box;
}
.button-bg:hover,
.button-outline:hover {
  background-color: #6b4701;
  color: $white !important;
  span,
  div {
    color: $white;
  }
}
.button-saved {
  pointer-events: none;
}
