.tab_label {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0px;
  &_text:not(:last-child) {
    margin-right: 5px;
  }
  &_text {
    font-size: 12px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 28px;
    padding: 4px 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
