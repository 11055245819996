//
// Sidemenu
//

.side-menu {
  min-width: 128px;
  max-width: 128px;
  height: 100%;
  min-height: 560px;
  background-color: $sidebar-bg;
  display: flex;
  justify-content: center;
  z-index: 9;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  // padding: 10px 10px;

  @media (max-width: 991.98px) {
    position: fixed;
    bottom: 0;
    height: 80px !important;
    min-width: 100%;
    min-height: auto;
    display: block;
    border-top: 1px solid $border-color;
    padding: 5px 10px;
  }

  .navbar-brand-box {
    text-align: center;

    @media (max-width: 991.98px) {
      display: none;
    }

    .logo {
      line-height: 70px;
    }

    .logo-dark {
      display: $display-block;
    }

    .logo-light {
      display: $display-none;
    }
  }

  .theme-mode-icon {
    &:before {
      content: $theme-mode-icon;
    }
  }
}
.side-menu-nav::-webkit-scrollbar {
  width: 1px;
}

.side-menu-nav::-webkit-scrollbar-thumb {
  background-color: $option_color;
  border-radius: 5px;
}
.side-menu-nav::-webkit-scrollbar-track {
  background-color: $option_color;
}

.side-menu-nav {
  height: 100vh;
  overflow-y: scroll;
  flex-direction: column;
  @media (max-width: 991.98px) {
    padding-top: 0px;
    flex-direction: row;
  }
  .nav-item {
    // margin: 5px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 991.98px) {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0px;
    }

    &.active {
      background-color: $sidebar-menu-item-active-bg;
      i {
        color: $primary;
      }
      h6 {
        font-weight: 700;
        opacity: 1;
      }
      .circle_out {
        background-color: $sidebar-menu-item-color;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
      }
      .circle_in {
        background-color: #f9c901;
      }
    }
    h6 {
      font-size: 14px;
      color: black;
      text-align: center;
      line-height: 22px;
      margin-top: 2px;
      font-family: 'Nunito';
      opacity: 0.7;
      font-weight: 300;
      @media (max-width: 991.98px) {
        display: none;
      }
    }
    .circle_out {
      height: 58px;
      width: 58px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .circle_in {
      position: relative;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      background-color: $sidebar-menu-item-color;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav-link {
      text-align: center;
      font-size: 24px;
      color: $sidebar-menu-item-color;
      margin: 0px auto;
      padding: 0;

      @media (max-width: 991.98px) {
        font-size: 20px;
        width: 48px;
        height: 48px;
        line-height: 48px;
      }

      i {
        font-size: 40px;
      }
    }

    &.show > .nav-link {
      background-color: $sidebar-menu-item-active-bg;
      color: $primary;
    }
  }

  .nav-item:hover {
    background: $sidebar-menu-item-active-bg;
  }
}

.sidebar-noti {
  position: absolute;
  width: 26px;
  height: 26px;
  background: #03c988;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  transform: translate(25%, -25%);
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 2px solid #fff;
}
