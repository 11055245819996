.popup-contai-chat {
  .modal-content {
    padding: 40px;
    border-radius: 40px;
    font-family: 'Nunito' !important;
    font-style: normal !important;
    h5 {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: black;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0px;
    }
    .popup-chat-header {
      display: flex;
      justify-content: center;
      border-bottom: none;
      padding: 0 !important;
      h4 {
        font-family: 'Nunito';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        color: black;
      }
    }
  }
}

//new chat popup
.newChat-contai {
  button.dropdown-toggle {
    background-color: unset !important;
    border-color: unset !important;
  }
  button.dropdown-toggle:active {
    background-color: unset !important;
    border-color: unset !important;
  }
  .newChat-body {
    .newChat-top-body {
      padding: 16px 18px;
      border-radius: 20px;
      background-color: $option_color;
      margin-top: 24px;
    }
  }
}
.newChat-list-user > div:first-child .recommended {
  display: block;
}
.newChat-list-user {
  margin-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .payment_items_method {
  }
  .newChat-item-user {
    position: relative;
    background-color: $option_color;
    padding: 16px 18px;
    border-radius: 20px;
    display: flex;
    cursor: pointer;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
    @media only screen and (max-width: 500px) {
      h5,
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 160px;
      }
    }
    h5 {
      text-align: start;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0px;
      text-align: start;
    }
    .user-view {
      display: flex;
      align-items: center;
    }
    .user-chat-content {
      flex-direction: column;
      margin-left: 16px;
      margin-right: 30px;
      min-width: 258px;
    }
    .user-chat-logo {
      padding: 20px;
      border-radius: 14px;
    }
    .recommended {
      position: absolute;
      padding: 4px 16px;
      background-color: #03c988;
      top: 0px;
      right: 0px;
      border-radius: 0px 16px;
      font-size: 12px;
      display: none;
    }
  }
  .newChatItem {
    padding: 16px 18px 16px 2px;
    .user-chat-content {
      margin-left: 2px;
    }
  }
}
.isPaymentClass.user-chat-logo {
  background-color: $header-bg;
}

.contai_new_chat_modal .user-chat-logo {
  border-radius: 14px !important;
  padding: 0px !important;
  img {
    width: 62px;
    height: 62px;
  }
}
.contai_new_chat_modal .newChatClass img {
  width: 90px;
  height: 90px;
}
.purchase_char {
  display: flex;
  flex-direction: column;
  label {
    text-align: start;
    font-size: 14px;
  }
}
.card-body-custom {
  padding: 0;
  .option-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $option_color;
    padding: 16px;
    border-radius: 24px;
    gap: 50px;
  }
}
.purchase-modal-body {
  margin-top: 20px;

  h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: #000;
    text-align: start;
  }
}
.itempurchase-contai {
  .modal-header {
    position: relative;
  }
  .itempurchase-back {
    position: absolute;
    top: 15px;
    left: 20px;
    cursor: pointer;
  }
}

.lisAction-contai {
  width: fit-content;

  .list-listAction {
    padding: 20px !important;
    border-radius: 20px;
  }
  .item-listAction {
    display: flex;
    gap: 14px;
    padding: 13px;
    border-radius: 30px;
    margin: 5px 0px;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
      color: #000;
    }
    img {
      opacity: 1;
    }
  }
  .item-action-del {
    color: #e76161;
  }
  .item-listAction:hover {
    background-color: $option_color;
    img {
      opacity: 1;
    }
  }
}
