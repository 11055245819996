//
// user chat.scss
//

// user chat

.user-chat {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  width: calc(100% - 486px);
  height: 100%;
  background-color: $white;
  z-index: 1;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
      .header_chat_list h5 img {
        display: inline;
        cursor: pointer;
      }
    }
  }

  .empty_conversation {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty_image {
      padding: 33px;
      background-color: rgba(249, 201, 1, 0.1);
      border-radius: 50%;
      width: fit-content;
      margin-bottom: 20px;
    }
    .title_empty {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .header_chat_list {
    padding: 10px 30px;
    background-color: rgba(249, 201, 1, 0.10000000149011612);

    h5 {
      font-size: 18px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      img {
        display: none;
      }
    }
  }
}

.user-chat-nav {
  .nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    color: $gray-600;
  }
}

.chat-conversation {
  background-color: $white;
  padding: 20px 20px;

  li {
    clear: both;
  }

  .chat-avatar {
    margin-right: 16px;
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: $border-color;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    max-width: 70%;
    .user-chat-content {
      padding: 14px 20px;
      background-color: $option_color;
      border-top-left-radius: 47px;
      border-top-right-radius: 47px;
      border-bottom-right-radius: 47px;
      position: relative;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      p {
        font-size: 14px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .time_user_chat {
        position: absolute;
        margin-top: 4px;
        top: 100%;
        left: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
      }
    }

    .conversation-name {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-time {
      font-size: 12px;
      text-align: right;
      color: $gray-600;
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        max-width: 150px;
      }

      .message-img-link {
        position: absolute;
        right: 10px;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: $white;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .right {
    .chat-avatar {
      order: 3;
      margin-right: 0px;
      margin-left: 16px;
    }

    .chat-time {
      text-align: right;
      // color: $gray-600;
    }

    .conversation-list {
      float: right;
      .user-chat-content {
        background-color: $header_bg;
        border-bottom-left-radius: 47px;
        border-bottom-right-radius: 0px;
        position: relative;
        overflow-wrap: break-word;
        white-space: pre-wrap;
         p {
        .message-link {
          color: #1791f5;
        }
        }
        p {
          .message-link:hover {
            color: #0684eb;
          }
        }

        .time_user_chat {
          top: 100%;
          right: 0;

          display: flex;
          justify-content: flex-end;
        }
      }

      .dropdown {
        order: 1;
      }
    }

    .dot {
      background-color: $dark;
    }
  }
}

.chat-conversation {
  height: calc(100vh - 240px) !important;
  @media (max-width: 991.98px) {
    height: calc(100vh - 192px);
  }
}

.chat-input-links {
  .list-inline-item:not(:last-child) {
    margin: 0;
  }
}

.wrapper_add_chat {
  padding: 10px 30px 20px 30px;
  z-index: 1;
  @media (max-width: 991.98px) {
    padding: 10px 20px 20px 20px;
  }
  .button_contai {
    display: flex;
    align-items: center;
    margin-right: 3px;
  }
  button {
    border: none !important;
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
    background-color: $option_color;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .contai_input_btn {
    border-radius: 23px;
    background-color: $option_color;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  }
}

.chat-read {
  position: relative;
  top: 5px;
  right: 5px;
  font-size: 15px;
  &.seen {
    color: #4fc3f7;
  }
}
.chat-input-wrapper {
  min-height: 45px;
  overflow: hidden;
  padding: 5px 30px;
  display: flex;
  align-items: center;

  .chat-input-compose {
    // display: flex;
    // align-items: center;
    font-size: 15px;
    font-weight: 400;
    max-height: 80px;
    // min-height: 35px;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    &[placeholder]:empty:before {
      content: attr(placeholder);
      color: #555;
    }
    width: 100%;
  }
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: -1px;
    background: $white;
    animation: wave 1.3s linear infinite;
    opacity: 0.6;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}

@media (min-width: 992px) {
  .emoji-dropdown {
    .dropdown-menu-lg-right {
      right: 0 !important;
      left: '-82px' !important;
      width: 360px !important;
    }
  }
}

.chat-label {
  display: inline;
  color: #fff;
  font-size: 10px;
  border-radius: 9px;
  padding: 5px;
  &.company {
    background-color: $cyan;
  }
  &.candidate {
    background-color: $yellow;
  }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
}

// animation ellipsis
.simplebar-content {
  min-height: 100%;
  position: relative;
}
#listMessages {
  min-height: 100%;
}
.item-chatGPT-sending {
  position: absolute;
  left: 30px;
  bottom: 4px;
}
.chatGPT-sending {
  display: flex;
  align-items: flex-end;

  .avatar-loading {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.5px solid rgb(120, 120, 120);
    img {
      height: 16px;
    }
  }
  .host {
    background: #fff;
    width: 70px; /* 4 dots * (10px size + 5px spacing) */
    height: 20px;
    margin-left: 20px;
  }

  .loading {
    width: 10px;
    height: 10px;
    background: $header_bg;
    border-radius: 100%;
    float: left;
    margin-right: 5px;
    margin-top: 10px;
  }

  .loading-0 {
    animation: bounce 1s infinite;
    animation-delay: 0.1s;
  }

  .loading-1 {
    animation: bounce 1s infinite;
    animation-delay: 0.3s;
  }

  .loading-2 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.5s;
  }

  .loading-3 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.7s;
  }

  @keyframes bounce {
    0%,
    100% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
  }
}

// .loading {
//   position: absolute;
//   left: 100%;
//   width: 15px;
//   height: inherit;
//   font-size: 15px;
// }

// .loading::after {
//   overflow: hidden;
//   display: inline-block;
//   vertical-align: bottom;
//   -webkit-animation: ellipsis steps(4, end) 900ms infinite;
//   animation: ellipsis steps(4, end) 900ms infinite;
//   content: '\2026'; /* ascii code for the ellipsis character */
//   width: 0px;
// }

// @keyframes ellipsis {
//   to {
//     width: 1.25em;
//   }
// }

// @-webkit-keyframes ellipsis {
//   to {
//     width: 1.25em;
//   }
// }
