/*Button*/
.btn {
  height: 2.875rem;
  padding: 0px 1.5rem !important;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700 !important;
  color: #000000;
  border-radius: 1.875rem !important;
  box-shadow: var(--shadow-base);
  border: none;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
  text-decoration: none;
  transition: all 0.3s ease;
  background: #ffffff;
  border: 1px solid #a3b2c7;

  &:hover {
    color: #fff !important;
    background: #6b4701 !important;
    text-decoration: none;
    border-color: #6b4701 !important;
  }

  &:hover {
    & span {
      color: #fff;
    }

    & i {
      color: #fff;
    }
  }

  & > i {
    font-size: 1.25rem;
  }

  &.btn-primary {
    background: var(--color-primary);
    border-color: var(--color-primary);
  }

  &.btn-logout {
    font-size: 0.875rem;
    color: #e76161;

    & > i,
    & > span {
      font-size: 0.875rem;
      color: #e76161;
    }
  }
}
