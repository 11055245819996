.otp-container {
  input {
    outline: none;
    border: 1px solid #888;
    &:focus {
      outline: 2px solid #f7a703;
      border: 2px solid #f7a703 !important;
    }
  }

  h5:hover {
    cursor: pointer;
  }
}

.verify-page {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      text-align: center;
      flex: 1;
      position: absolute;
    }
  }

  .back-button {
    cursor: pointer;
    margin-right: 16px;
    flex: 1;
  }

  .verify-button {
    width: 100%;
    border-radius: 20px;
    margin-top: 5rem;
  }
}
