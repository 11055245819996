.day_wrapper{
    width: 120px;
    position: relative;
    margin-right: 20px;
    .list_days.show{
        position: absolute;
        right: 0px !important;
        transform: none !important;
        width: 120px;
        box-sizing: border-box;
        min-width: 120px !important;
    }
    .item_day:hover{
        background-color: #f6f6f6;
    }
}
.day_selected{
    border-radius: 1.5625rem;
    background-color: #f6f6f6;
    box-shadow: var(--shadow-base);
    color: #000;
    text-align: center;
    
}
.add_time_day{
    border: none;
    padding: 0px 20px;
    font-size: 24px;
}

.day_time_wrapper{
    margin-bottom: 20px;
    .bin_wrapper{
        margin-left:  20px;
        padding-top: 6px;
        cursor: pointer;
        i{
           font-size: 20px;
        }
    }

    .bin_wrapper:hover i{
        color: red;
    }
    .start_end_time{
        margin-left:  20px;
        border-radius: 1.5625rem;
        background-color: #f6f6f6;
        box-shadow: var(--shadow-base);
        display: flex;
        align-items: center;
        padding: 0px 20px;
        .input_time{
            width: 25px;
            border: none !important;
            background-color: #fff;
            padding: 2px;
            margin: 0px 2px;
            text-align: center;
            border-radius: 3px;
        }
        .input_time:focus-visible{
            outline: none !important;
        }
    }
}
@media only screen and (max-width: 1345px) {
    .item_td_body, .item_thead{
        font-size: 15px !important;
        padding: 0px 3px !important;
    }
    .comission-data th:first-child, 
    .comission-data td:first-child{
        padding-left: 10px !important;
    }
  }
.payment-status.in.kind, .payment-status.admin{
    color: rgb(120, 120, 120)
}