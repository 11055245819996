.credit-card {
  .modal-body {
    padding: 0px !important;
  }
}
.payment {
  display: flex;
  justify-content: center;
  width: 1000px;
  height: 570px;
  margin: auto;

  &-left {
    width: 510px;
    background: $option_color;
    margin-right: 10px;
    border-bottom-left-radius: 40px;

    &_text {
      border: 1px solid #a3b2c7;
      padding: 12px 16px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      span {
        text-align: start;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .wrapper-blance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $white;
      padding: 10px;
      margin: 16px 0px;
      font-weight: 700;
      border-radius: 25px;
      &_right {
        font-size: 16px;
      }
      &_left {
        font-size: 13px;
        margin-left: 20px;
      }
    }

    .wrapper_buy-beebux_card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 14px;
      cursor: pointer;

      .buy-beebux_card {
        padding: 9px;
        width: 130px;
        border: 1px solid $gray-500;
        border-radius: 10px;

        .currency_beebux {
          font-size: 13px;
        }
        .currency {
          font-weight: 700;
        }
      }

      .buy-beebux_card:hover {
        background: $yelow-700;
        color: $white;
      }

      .buy-beebux_card-active {
        background: $yelow-700;
        color: $white;
      }
    }
    input {
      border-radius: 25px;
      border: 1px solid #a3b2c7;
      padding: 20px 15px;
    }

    .money {
      padding: 10px 0px;
      font-weight: 700;
      text-align: start;
      span {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &-right {
    width: 470px;
    background: white;
    border-bottom-right-radius: 40px;
    margin-left: 10px;
    .container_form_group {
      text-align: start !important;
      margin-top: 15px;
      .title_card {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
      }
    }

    .card_input_contai {
      .icon_input {
        background-color: $option_color;
        border: none;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        box-shadow: 10px 6px 10px 0px rgba(0, 0, 0, 0.15);
      }
      .text_input {
        background-color: $option_color;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: 10px 6px 10px 0px rgba(0, 0, 0, 0.15);

        border: none;
      }
      .text_input:focus {
        border-color: none;
        box-shadow: none;
      }
    }
    .submit-contai:hover {
      background-color: #6b4701 !important;
      span {
        color: #fff !important;
      }
    }
    .submit-contai:focus {
      border-color: none;
      box-shadow: none;
    }

    .list-payment-card {
      display: flex;
      justify-content: center;
      gap: 17px;

      .payment-card {
        // width: 70px;
        height: 20px;
      }
    }
  }
}
