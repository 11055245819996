.contai-profile {
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;

  .profile-help {
    position: absolute;
    bottom: 20px;
    right: 20px;
    @media (max-width: 991.98px) {
      bottom: 90px;
    }
  }

  .library {
    height: calc(100vh - 271px);
    .labrary_question {
      height: calc(100vh - 300px);
      margin: 10px 0px;
    }
  }
}

.profile-user-page {
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  padding: 30px 20px 0px 20px;

  .profile-content {
    .username {
      margin-bottom: 10px;
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .profile-contai-info {
      height: 171px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .profile-content__info {
        justify-content: space-between;
        width: 100%;
        margin-left: 30px;
        .profile-info-item {
          display: flex;
          label {
            width: 80px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            margin: 0px;
          }
          h5 {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            margin: 0px;
          }
        }
      }

      .profile-info-left {
        display: flex;
        height: 140px;
        align-items: flex-end;
        padding: 16px 25px;
        background-color: $option-color;
        border-radius: 20px;
        .profile-contai-content {
          display: flex;
          justify-content: space-between;
          .listBtn-right {
            display: flex;
            align-items: flex-end;
            .dropdown-toggle {
              padding: 0px;
            }
            .dropdown-toggle {
              border: none;
              outline: none !important;
              background-color: transparent;
              color: #000 !important;
            }
            .dropdown-toggle:focus {
              box-shadow: none !important;
            }
            .list-listAction {
              padding: 11px 0px !important;
            }
            .item-listAction {
              border-radius: 0px;
              margin: 0px;
              padding: 11px 20px;
            }
            .item5 p,
            .item7 p {
              color: #e76161;
            }
          }
        }
      }
    }
    .profile-content__img {
      .img-wrapper {
        border-radius: 155px;
        border: 7.75px solid $option-color;
        margin-bottom: -7px;
        width: fit-content;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        img {
          height: 155px;
          width: 155px;
          border-radius: 155px;
        }
      }
    }
    //question saved
  }
  .profile-content__favorite {
    // .option-custom {
    //   margin: 30px 30px 0px 0px !important;
    // }
    // .contai_skillset {
    //   padding: 0px;
    // }
    // .price-wrapper {
    //   padding: 0px;
    // }
    // .sort-wrapper {
    //   padding: 0px;
    // }
  }

  //profile info

  // .profile-content__edit {
  //   display: flex;
  //   flex-direction: column;
  //   h5 {
  //     margin-top: 20px;
  //     margin-bottom: 20px;
  //   }
  //   .form-group {
  //     display: flex;
  //     align-items: center;
  //     width: 400px;
  //     .input-group {
  //       width: unset;
  //       flex: 1;
  //     }

  //     label {
  //       width: 40%;
  //     }
  //   }
  // }

  // .profile-content__header {
  // }

  // .profile-sidebar {
  //   // height: 100%;

  //   border-right: 1px solid black;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   &__logo {
  //     margin-top: 10px;
  //     width: 100%;
  //     padding: 0 20px 0 0;
  //     height: 100px;
  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  //   &__info {
  //     margin-top: 20px;
  //     display: flex;
  //     align-items: center;
  //     flex-direction: column;
  //     div.img {
  //       width: 80px;
  //       height: 80px;
  //       border-radius: 50%;
  //       overflow: hidden;
  //       border: 1px solid;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //     h5 {
  //       margin: 16px 0;
  //     }

  //     p {
  //     }
  //   }
  //   ul {
  //     margin-top: 25px;
  //     list-style: none;
  //     padding-left: 0;
  //     li {
  //       border: 1px solid;
  //       display: flex;
  //       align-items: center;

  //       padding: 8px 40px;
  //       margin-bottom: 20px;
  //       border-radius: 30px;
  //       transition: all 0.2s;

  //       &:hover {
  //         cursor: pointer;
  //         border-color: $yellow;
  //         i {
  //           color: $yellow;
  //         }
  //         span {
  //           color: $yellow;
  //         }
  //       }

  //       i {
  //         margin-right: 10px;
  //         font-size: 20px;
  //         transition: all 0.2s;
  //       }
  //       span {
  //         font-size: 20px;
  //         transition: all 0.2s;
  //       }
  //     }
  //   }
  // }

  // .profile-content {
  //   height: calc(100vh - 150px);
  //   &__header {
  //     border-bottom: 1px solid;
  //     height: 120px;
  //     position: relative;

  //     .dropdown-menu {
  //       min-width: 200px;
  //     }

  //     .username-wrapper {
  //       position: absolute;
  //       left: 330px;
  //       bottom: 20px;
  //       display: flex;
  //       .username {
  //         i {
  //           font-size: 24px;
  //           &:hover {
  //             cursor: pointer;
  //           }
  //         }
  //         h4 {
  //           // font-size: 24px;
  //           // margin-bottom: 0;
  //           // margin-right: 10px;
  //           font-size: 24px;
  //           font-weight: 700;
  //           line-height: 36px;
  //         }
  //       }
  //       .input-group {
  //         width: 250px;
  //         display: flex;
  //         align-items: center;
  //         margin-bottom: 0 !important;

  //         input {
  //           border: none;
  //           background-color: #f7f7fe !important;
  //           border-bottom: 1px solid;
  //           border-radius: 0;
  //           margin-right: 5px;
  //           padding-left: 5px;
  //         }
  //       }
  //     }
  //     .menu-dropdown {
  //       position: absolute;
  //       top: 10px;
  //       right: 50px;
  //       & > div {
  //         display: block !important;
  //       }
  //     }
  //   }

  //   &__body {
  //     position: relative;
  //     height: calc(100vh - 150px);
  //     .profile-content__img {
  //       position: absolute;
  //       z-index: 10;
  //       top: -100px;
  //       left: 60px;
  //       background-color: #f7f7fe;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       justify-content: center;
  //       margin-bottom: 20px;

  //       li {
  //         & > button:first-of-type {
  //           padding: 0;
  //           width: 200px;
  //           height: 200px;
  //           border-radius: 50% !important;
  //         }
  //       }

  //       h3 {
  //         margin-bottom: 20px;
  //       }
  //       .profile-img {
  //         display: flex;
  //         flex-direction: column;
  //         align-items: center;

  //         .img-wrapper {
  //           border: 1px solid $yellow;
  //           border-radius: 50%;
  //           overflow: hidden;
  //           width: 200px;
  //           height: 200px;
  //           position: relative;

  //           &:hover {
  //             cursor: pointer;
  //           }

  //           img {
  //             width: 100%;
  //             height: 100%;
  //           }
  //         }
  //       }

  //       .upload-wrapper {
  //         position: absolute;
  //         bottom: 0;
  //         // right: 20px;
  //         z-index: 10;
  //         &:active {
  //           opacity: 0.5;
  //         }

  //         input {
  //           display: none;
  //         }

  //         label {
  //           display: flex;
  //           align-items: center;
  //           border-radius: 20px;
  //           border: 1px solid $yellow;
  //           width: 200px;
  //           padding: 6px 0;
  //           justify-content: center;
  //           background: white;
  //           margin-bottom: 0;
  //           &:hover {
  //             cursor: pointer;
  //           }
  //         }

  //         i {
  //           font-size: 20px;
  //         }
  //       }
  //     }

  //     .profile-content__edit {
  //       display: flex;
  //       flex-direction: column;
  //       margin-left: 330px;
  //       h5 {
  //         margin-top: 20px;
  //         margin-bottom: 20px;
  //       }
  //       .form-group {
  //         display: flex;
  //         align-items: center;
  //         width: 400px;
  //         .input-group {
  //           width: unset;
  //           flex: 1;
  //         }

  //         label {
  //           width: 40%;
  //         }
  //       }
  //     }

  //     .profile-content__favorite {
  //       position: relative;

  //       .library {
  //         .library_content {
  //           height: inherit;

  //           .labrary_question {
  //             // height: 800px;
  //           }
  //         }
  //       }

  //       h5 {
  //         border-top: 1px solid;
  //         border-bottom: 1px solid;
  //         padding: 8px 0 8px 16px;
  //         margin-bottom: 0;
  //         font-size: 24px;
  //       }

  //       .filter-wrapper {
  //         display: flex;
  //         padding-top: 16px;
  //         .filter-sidebar {
  //           width: 30%;
  //           margin: 0 16px;
  //           border: 1px solid;
  //           border-radius: 10px;

  //           h6.title {
  //             padding: 8px;
  //             border-bottom: 1px solid;
  //           }

  //           form {
  //             padding: 0 16px;
  //             .sort {
  //               margin-bottom: 20px;
  //               & > div {
  //                 margin-bottom: 8px;
  //                 display: flex;
  //                 align-items: center;
  //                 label {
  //                   margin-bottom: 0;
  //                 }
  //                 input {
  //                   margin-right: 8px;
  //                 }
  //               }
  //             }

  //             .option-custom {
  //               margin-bottom: 16px;
  //             }

  //             .form-submit__filter {
  //               display: flex;
  //               align-items: center;
  //               margin-bottom: 20px;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.modal-language {
  .modal-content {
    width: 70%;
    margin: auto;
  }
}

.modal-avatar {
  .card-body {
    & > div {
      img {
        width: 100%;
      }
    }
  }
}

.icon-wrapper i {
  color: #fff;
}
