.transactions {
  padding: 20px 50px;
  background: $white;
  flex-grow: 1;
  height: calc(100vh - 70px);
  font-family: 'Nunito';
  font-style: normal;

  &_wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
  &_header {
    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $option-color;
      padding: 15px;
      border-radius: 15px;

      &_left {
        color: $black;
        .beebux {
          font-size: 20px;
          font-weight: 700;
          span {
            font-size: 14px;
            font-weight: 400;
          }
        }
        .balance {
          color: #848484;
        }
      }

      &_right {
        .buy_btn {
          color: $black;
          border-radius: 30px;
          background: $header-bg;
          padding: 12px;
          line-height: 1;
          font-weight: 700;
        }
        .buy_btn:hover {
          background-color: #6b4701;
          color: $white;
          border-color: unset;
        }
        .buy_btn:focus {
          border-color: unset;
          box-shadow: none;
        }
      }
    }
  }

  &_content {
    height: calc(100vh - 350px);
    padding-right: 10px;
    overflow: auto;

    .transaction_card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #a3b2c7;
      padding: 15px 0px;
      color: $black;
      cursor: pointer;

      &_left {
        &_name {
          font-size: 14px;
          font-weight: 700;
        }
        &_id {
          font-size: 12px;
          font-weight: 500;
          opacity: 0.5;
        }
        .transaction_card_right_created {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          opacity: 0.5;

          span {
            position: relative;
            top: 1px;
            left: 10px;
          }
        }
        .image-transaction {
          display: flex;
          align-items: center;
          justify-content: center;
          // padding: 17px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: $header-bg;
        }
        .transaction_card_left_content {
          margin-left: 30px;
        }
      }

      &_right {
        &_amount {
          font-weight: bold;
          color: $red;
        }
        .amount_green {
          color: $green;
        }
      }
    }
  }

  &_summary {
    text-align: start;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
}

.transaction-modal {
  color: $black;

  &_title {
    text-align: center;
    margin-bottom: 10px;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    &_right {
      text-align: end;
      .transaction_modal_mount {
        color: $red;
      }

      .amount_green {
        color: $green;
      }
    }
  }
}

.modal-payment {
  // max-width: 200px;
  .icon-back {
    position: absolute;
    top: 50%;
    left: 20px;
    font-size: 20px;
    transform: translateY(-50%);
    &:hover {
      cursor: pointer;
    }
  }
  .modal-content {
    border-radius: 40px !important;
    width: fit-content;
  }

  .modal-header {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    border-radius: 40px 40px 0px 0px;
    border-bottom: 0.5px solid #a3b2c7;
    background-color: $option-color;
    .title_img {
      text-align: center;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: $black;
      margin: 0px;
    }
    button {
      position: absolute;
      top: 25px;
      right: 25px;

      font-size: 20px !important;
      opacity: 1 !important;
    }
    .backIcon {
      position: absolute;
      top: 22px;
      left: 30px;
      width: 20px;
    }
  }
  .modal-body {
    text-align: center;
    justify-content: center;
    padding: 10px 40px;
    label {
      margin: 0px;
    }
  }

  // .modal-body-insuff {
  //   max-width: 350px;
  // }

  .modal-footer {
    padding: 10px 40px 40px 40px;
    justify-content: center;
    gap: 20px;
    border-top: none;
    .cicle-img {
      padding: 15px;
      border-radius: 50%;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
      margin: 8px;
    }
    button {
      width: fit-content;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3.title {
      font-size: 18px;
      margin: 0 0 10px 0;
    }

    ul {
      list-style: none;
      padding-left: 0;
      li {
        display: flex;
        align-items: center;
        border: 2px solid $warning;
        border-radius: 10px;
        padding: 0 16px;
        margin-bottom: 10px;
        &:hover {
          cursor: pointer;
        }

        div {
          h3 {
            font-size: 14px;
            margin: 12px 0 8px 0;
          }
          p {
            color: #888;
          }
        }

        i {
          font-size: 30px;
          color: $black;
        }
      }
    }
  }
}
.unlock-modal {
  max-width: 400px;
}
.insuffient-modal {
  max-width: 470px;
  .modal-header {
    background-color: #fff;
    border-bottom: none;
  }
  .modal-body {
    width: fit-content;
    label {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.modal-share {
  .modal-content {
    width: 390px;
    .modal-footer > div:hover {
      cursor: pointer;
    }
  }
}

.credit-card {
  max-width: 1050px;
}

.payment-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    p {
      font-weight: 400;
      font-size: 14px;
      color: #000;
      opacity: 0.699999988079071;
      text-align: start;
      margin: 0px;
    }
    p:first-child() {
      margin-top: 10px;
    }
    strong {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: start;
    }
    .opcity_Strong {
      opacity: 1;
    }
  }

  .phone-number {
    position: relative;
    margin: 16px 0;
    input {
      width: 100%;
      height: 100%;
      padding: 12px 18px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 23px;
      background-color: $option-color;
      border: unset;
    }
    i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 20px;
      z-index: 2;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .payment-img {
    width: 60%;
    height: auto;
    margin: 20px auto;
    img {
      width: 100%;
      height: auto;
    }
  }
}
