.course_filter {
  width: 300px !important;
  .library_content_filter {
    width: 300px !important;
  }
  .sort-wrapper,
  .contai_skillset {
    padding: 0px 20px !important;
  }
  .filter-action {
    position: unset !important;
    transform: unset !important;
  }
  .library_content_filter {
    height: unset !important;
    margin-bottom: 20px !important;
  }
}
.course_container {
  height: 100% !important;
  padding-bottom: 0px !important;
  //all my course
  .option_courses {
    width: 100%;
    text-align: start;
    padding: 20px 25px;
    .option-wrapper {
      display: flex;
      border-bottom: 0.5px solid rgba(163, 178, 199, 0.5);
      .option-item {
        padding: 10px 51px;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
      }
      .option-item-active {
        color: $header-bg;
        border-bottom: 2px solid $header-bg;
      }
    }
  }
  //end all my course

  //start input search
  .searchCourse {
    padding: 0px 25px;
    .inputWrapper {
      position: relative;
      .input_tag_search {
        padding: 11px 44px;
        border-radius: 23px;
        background-color: $option_color;
        box-shadow: $boxShadow;
        border: unset;
        outline: unset;
      }
      .searchIcon {
        position: absolute;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  //end input search

  //start list course
  .courseWrapper {
    padding: 20px 5px 0px 15px;
    width: 100%;
    height: calc(100% - 132px);
    .listCourse {
      overflow-y: auto;
      padding-bottom: 10px;

      @media (max-width: 991.98px) {
        height: calc(100% - 80px) !important;
      }
      .contaiContent {
        border-radius: 15.3px;
        box-shadow: $boxShadow;
        text-align: start;
        position: relative;
        cursor: pointer;
        .rightFooter {
          position: absolute;
          bottom: 20px;
          right: 10px;
        }
        .course-img {
          overflow-y: hidden;
          img {
            width: 100;
            height: auto;
            border-top-left-radius: 15.3px;
            border-top-right-radius: 15.3px;
          }
        }
      }
    }
  }
}
.common-course {
  .course-rate {
    padding: 10px 17px;
    .nameCourse {
      margin: unset;
      font-size: 20px;
    }
    img {
      margin-right: 5px;
    }
    .cout_rate {
      font-size: 12px;
      margin-top: 3px;
    }
  }

  .course-info {
    padding: 0px 17px;
    .itemInfo {
      p {
        margin: unset;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .rightInfo {
        font-weight: 700;
        padding-left: 5px;
      }
    }
  }
  .course-cetegory {
    padding: 0px 17px;
    .contentCategory {
      border-top: 0.5px solid #a3b2c7;
      padding: 16px 0px;
      .leftFooter {
        max-width: calc(100% - 85px);
        span {
          font-size: 14px;
          padding: 2px 12px;
          border-radius: 20px;
          height: fit-content;
          white-space: nowrap;
          float: left;
          margin-top: 5px;
          margin-right: 10px;
        }
      }
      .rightFooter {
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          margin-right: 5px;
          white-space: nowrap;
        }
      }
    }
  }
}
