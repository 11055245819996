.library {
  height: calc(100vh - 70px);
  overflow-y: auto;
  width: calc(100vw - 128px);
  @media (max-width: 991.98px) {
    width: calc(100vw);
  }

  &_content {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    height: 100%;
    .labrary_filter {
      position: relative;
      width: 360px;
      border-right: 0.5px solid #a3b2c7;
      background-color: $white;
      height: 100%;
      @media (max-width: 991.98px) {
        height: calc(100% - 80px);
      }
      .library_content_filter {
        margin-top: 30px;
        height: calc(100% - 186px);
        overflow-y: scroll;
        width: 360px;
        border-right: 0.5px solid #a3b2c7;
      }
      .library_content_filter::-webkit-scrollbar {
        width: 2px;
      }
      .library_content_filter::-webkit-scrollbar-thumb {
        background-color: $header_bg;
        height: 10px;
      }
      label {
        font-size: 14px;
        line-height: 22px;
        font-family: 'Nunito';
      }

      .contai_skillset {
        padding: 0px 30px;
        .skillset-content {
          .skill {
            display: inline-flex;
            align-items: flex-start;
            margin: 3px;
            cursor: pointer;

            label {
              margin-left: -30px;
              margin-bottom: 0px;
              cursor: pointer;
              padding: 5px 15px;
              border-radius: 20px;
              background: $option_color;
            }
            label:hover {
              background: $header_bg;
            }
            input[type='checkbox']:checked + label {
              background: $header_bg;
            }
            input[type='checkbox'] {
              opacity: 0;
            }
          }
        }
      }
      h6 {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        font-family: 'Nunito';
      }
      &_title {
        color: $black;
        padding: 10px;
      }
      hr {
        border-color: $gray-500;
        margin: 0px;
      }

      &_content {
        padding: 10px;
        font-size: 13px;
        height: calc(100% - 95px);
        overflow: auto;

        .filter_title {
          font-size: 16px;
          margin: 8px 0;
          color: $black;
          padding-bottom: 5px;
          font-weight: 500;
        }
      }

      .price-wrapper {
        margin-top: 20px;
        padding: 0px 30px;
        .price-content {
          input[type='radio'] {
            opacity: 0;
          }
          .price {
            display: inline-block;
            margin: 3px;
          }
          label {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            border-radius: 20px;
            margin-left: -30px;
            cursor: pointer;
            background: $option_color;
            padding: 8px 14px;
          }
          label:hover {
            background: $header_bg;
          }
          input[type='radio']:checked + label {
            background: $header_bg;
          }
        }
      }

      .sort-wrapper {
        margin-top: 20px;
        padding: 0px 30px;
        .sort-content {
          .sort {
            input {
              box-shadow: 0 0 0 1pt #a3b2c7;
              border-radius: 50%;
              cursor: pointer;
              line-height: 0;
              margin: 0 10px 10px 0;
              padding: 0 !important;
              vertical-align: text-top;
              height: 17px;
              width: 17px;
              -webkit-appearance: none;
            }
            input:hover {
              opacity: 1;
            }

            input:checked {
              border: 2px solid $white;
              background-color: $header_bg;
              box-shadow: 0 0 0 1pt $header_bg;
              opacity: 1;
              box-sizing: border-box;
              outline: none;
            }
            label {
              cursor: pointer;
            }
          }
        }
      }

      .filter-action {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -150%);
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .button-bg,
        .button-outline {
          width: 142px;
        }
      }
    }

    .infinite-scroll-component__outerdiv {
      flex: 1;
    }

    .labrary_question {
      padding-bottom: 30px;
      flex-grow: 1;
      overflow-y: auto;
      height: calc(100vh - 100px);
      overflow-y: auto;
      text-align: center;
      position: relative;
      .card_wrapper {
        margin-top: 18px;
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }
}
