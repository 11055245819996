//
// chat-leftsidebar.scss
//

.chat-leftsidebar {
  background-color: $sidebar-sub-bg;
  position: relative;
  width: 360px;
  border-right: 0.5px solid #a3b2c7;
  background-color: $white;
  min-height: 100%; /* Changed height: 100% to min-height: 100% */
  font-family: 'Nunito' !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  box-sizing: border-box;
  overflow-x: visible !important;
  @media (max-width: 991.98px) {
    height: calc(100% - 80px);
    width: 100%;
  }

  .simplebar-content-wrapper {
    overflow: visible !important;
  }
  .sidebar_list_user {
    overflow: visible !important;
  }
  .chat-message-list {
    overflow-x: visible;
    overflow-y: auto;
    width: 800px;
  }
  .simplebar-mask {
    overflow: visible !important;

    box-sizing: unset;
  }

  .tab_content_sidebar {
    height: 100%;
    .top-sideleft {
      padding: 30px 30px 0px 30px;
      margin-bottom: 15px;
      button {
        max-width: 300px;
      }
    }
  }

  .dropdown-menu {
    // position: absolute !important;
    z-index: 9999 !important;
  }
  .add_new_wrapper {
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .title_chat_AI {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h5 {
        margin: 0px;
        font-size: 20px;
        font-weight: 700;
        color: #000;
      }
    }
  }

  .add_new_chat {
    padding-top: 15px;
    border-top: 0.5px solid #a3b2c7;
    width: 100%;
    .button-bg {
      display: flex;
      padding: 7px 18px;
      gap: 6px;
      align-items: center;
      span {
        font-size: 12px;
        font-weight: 700;
      }
      span:first-child() {
        font-size: 24px;
        margin-top: -3px;
        font-weight: 500;
      }
    }
  }

  .sidebar_list_user {
    height: calc(100% - 230px);
    overflow-y: visible;
  }

  .user-status-box {
    background-color: $light;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    margin-top: 16px;
    display: block;

    .chat-user-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      padding: 2px;
      border-radius: 50%;
    }
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }

  .search-icon-btn {
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 0;
  }
}
.tab_pane {
  height: 100%;
  padding-bottom: 30px;
}

.chat-message-list {
  height: calc(100%);
  padding-bottom: 30px;
  @media (max-width: 991.98px) {
    height: calc(100vh - 320px);
  }
}

.chat-group-list {
  height: calc(100vh - 160px);
  @media (max-width: 991.98px) {
    height: calc(100vh - 194px);
  }
}

.chat-list {
  margin: 0;
  .title_date_group {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    color: #000;
    padding-left: 30px;
  }

  li {
    position: relative;
    width: 360px;
    &.active {
      a {
        padding: 18px 14px;
        margin: 10px 16px;
        background-color: #f7e9ad;
        border-radius: 16px;

        .dropdown-toggle {
          background-color: #f7e9ad !important;
        }
      }
    }
    a:hover {
      padding: 18px 14px;
      margin: 10px 16px;
      background-color: #f7e9ad;
      border-radius: 16px;
      .dropdown-toggle {
        background-color: transparent;
        color: transparent;
      }
    }
    .dropdown-toggle {
      border: none;
      outline: none !important;
      background-color: transparent;
    }
    .dropdown-toggle:focus {
      box-shadow: none !important;
    }

    a {
      display: block;
      color: $gray-600;
      border-bottom: 0.5px solid #a3b2c7;
      padding: 18px 0px;
      margin: 10px 30px;
      .media {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: Nunito;
        font-style: normal;
        .left-chat-user {
          width: calc(100% - 40px);
          .dropdown-container {
            position: relative;
            width: calc(100% - 68px);
            display: flex;
            align-items: center;
            p {
              width: 190px;
            }
          }
        }
        h5 {
          position: relative;
          font-weight: 700;
          width: fit-content;
          .user-status {
            position: absolute;
            right: 0;
            top: 5px;
          }

          // &.online {
          //   .user-status {
          //     // background-color: $success;
          //   }
          // }

          &.away {
            .user-status {
              background-color: $warning;
            }
          }
        }
        p {
          font-weight: 400;
        }
      }
    }

    .chat-user-message {
      font-size: 14px;
    }

    &.typing {
      .chat-user-message {
        color: $primary;
        font-weight: $font-weight-medium;

        .dot {
          background-color: $primary;
        }
      }
    }

    .unread-message {
      position: absolute;
      display: inline-block;
      right: 24px;
      top: 33px;

      .badge {
        line-height: 16px;
        font-weight: $font-weight-semibold;
        font-size: 10px;
      }
    }
  }
}

.chat-user-img {
  position: relative;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

// contact list

.contact-list {
  li {
    cursor: pointer;
    padding: 10px 20px;
  }
}

// setting

.profile-user {
  position: relative;
  display: inline-block;

  .profile-photo-edit {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.search-result {
  .icon {
    cursor: pointer;
  }
  &[data-collapse='false'] {
    .search-list {
      display: block;
      transition: all 0.25s ease-out;
    }
  }
  &[data-collapse='true'] {
    .search-list {
      display: none;
      transition: all 0.25s ease-out;
    }
  }
}

.box-count-characters {
  background-color: $option_color;
  padding: 20px;
  border-radius: 16px;
  h6 {
    font-size: 14px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.rename-modal .renameInput {
  border: unset;
  border: 0.5px solid #a3b2c7;
  width: 300px;
  padding: 5px 15px;
  border-radius: 8px;
  margin-top: 20px;
  outline: none;
}
.rename-modal .renameInput:focus-visible {
  border: 0.5px solid #a3b2c7;
}
.rename-modal .action-rename {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
}
.rename-modal .disabledName {
  opacity: 0.5;
}
