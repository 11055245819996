.contai-profile-item {
  font-family: 'Nunito';
  font-style: normal;
  .breadcrumb-item {
    .breadcrumb {
      padding: 0px !important;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .title-change-pw {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
}
.profile_common_modal {
  font-family: 'Nunito';
  font-style: normal;
  line-height: 22px;
  border-radius: 20px;
  .modal-content {
    border-radius: 40px;
    max-width: 400px;
  }
  .profile_common_modal_body {
    padding: 10px 30px 20px 30px;
    .profile_modal_body_item {
      border-bottom: 0.5px solid #a3b2c7;
      margin-top: 20px;
    }
    .transferred_item {
      display: flex;
      justify-content: space-between;
    }
    .profile_modal_body_item:last-child {
      border-bottom: 0px solid #a3b2c7 !important;
    }
    .btn-save {
      margin: 40px 0px;
    }
  }
  .transaction_details_body {
    .transferred_item {
      .completed {
        color: $green;
      }
      h5 {
        font-size: 14px;
        font-weight: 700;
        color: #000;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
      .transaction_card_right_amount {
        color: $red;
      }
      .amount_green {
        color: $green;
      }
    }
  }
  .profile_common_modal_header {
    .modal-title {
      width: 100%;

      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: #000 !important;
    }
    background-color: $option-color;
    text-align: center;
    border: 0.5px solid var(--text-02, #a3b2c7);
    border-radius: 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }

  //edit profile
  .profie_edit_modal_body {
    .profile_edit_item {
      padding-bottom: 12px;
      .form-group {
        margin-bottom: 0px;
        > div {
          margin-bottom: 0px !important;
        }
      }
      .react-datepicker__input-container {
        height: 20px;
      }
      .date-picker-contai {
        background-color: white !important;
        padding-left: 15px !important;
      }
      label {
        color: #848484;
        font-size: 12px;
        font-weight: 500;
      }
      .profile_edit_input {
        position: relative;
        .icon_edit {
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          height: 18px;
          width: 18px;
        }
        input {
          width: 100%;
          padding-left: 30px;
          font-size: 14px;
          font-weight: 400;
          border: transparent;
          outline: none;
        }
      }
    }
  }
}

.wrapper-change-language {
  position: absolute;
  top: 100%;
  z-index: 999;
  width: fit-content;
  .dropdown-menu {
    border-radius: 20px;
    width: 200px;
    .item-listAction {
      position: relative;
      .tickIcon {
        position: absolute;
        top: 7px;
        left: 100%;
        transform: translateX(-30px);
      }
    }
  }
}
