//
// authentication.scss
//

.auth-logo {
  .logo {
    margin: 0px auto;
  }

  .logo-dark {
    display: $display-block;
  }

  .logo-light {
    display: $display-none;
  }
}

.auth-background {
  height: 100vh;
}

.left-background {
  // background-image: url('../../../images/login-background.svg');
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fdd323;
  overflow: hidden;
  img {
    background-color: #fdd323;
  }
  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 1;
    height: 12px;
    width: 12px;
    border: 1px solid #757575;
  }

  .swiper-pagination-bullet-active {
    background-color: $header-bg;
  }
}

.input-group-custom {
  box-shadow: 0px 6px 10px 0px #00000026;
  border-radius: 4rem;

  .input-custom {
    border-left-width: 0;
    border-right-width: 0;
    background-color: #f6f6f6 !important;
    padding-left: 0;
  }

  .input-custom:focus {
    box-shadow: unset !important;
  }

  .email-input-custom {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
    border: unset;
  }

  .addon-left-custom {
    background-color: #f6f6f6;
    border-top-left-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
    border: unset;
  }

  .addon-right-custom {
    background-color: #f6f6f6;
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
    cursor: pointer;
    border: unset;
  }

  .password-input-custom {
    border: unset;
  }
}

.error-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ec5151;
}

.forgot-password-btn {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 40px;
}

.forget-password-wrapper {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      text-align: center;
      flex: 1;
      position: absolute;
    }
  }

  .back-button {
    cursor: pointer;
    margin-right: 16px;
    flex: 1;
  }

  .description {
    margin: 0 3rem;
  }
}
