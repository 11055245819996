.library_detail {
  height: calc(100vh - 70px);
  width: calc(100vw - 128px);
  background-color: #fff;
  display: flex;
  font-family: 'Nunito' !important;

  .related_posts {
    background-color: $option_color;
    font-family: 'Nunito' !important;
    padding: 20px;
    width: 315px;
    overflow-y: auto;
    .related_title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: #000;
    }
    .question_related_list {
      margin-top: 20px;
      height: calc(100% - 50px);
      overflow-y: auto;
      .card_wrapper {
        margin: 10px 0px !important;
        box-shadow: none;
      }
      .card_wrapper,
      .card_left_content,
      .card_left {
        width: 100%;
        padding: 0px;
        border-radius: 7px;
        background-color: $white;
      }
      .card_left {
        padding: 10px 20px 10px 0px;
      }
      .card_left_name {
        font-size: 14px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 4px 0px;
        font-weight: 600 !important;
      }
    }
  }

  .library_detail_content {
    height: calc(100vh - 70px);
    width: calc(100% - 315px);
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .contai-content-library {
      height: calc(100% - 50px);
    }
    .topDetail {
      border-bottom: 0.5px solid #a3b2c7;
      margin: 0px 15px 15px 15px;
    }
    .library_detail_text {
      margin: 0px 15px 15px 15px;
    }
    .library_detail_thumbnails {
      margin: 0px 15px 15px 15px;
    }
    .title_question {
      display: flex;
      padding: 0px 30px 10px 30px;
      margin: 0px 15px 10px 15px;
      align-items: center;
      box-shadow: 0 8px 10px -6px rgb(149, 149, 149);
      .backImage:hover {
        cursor: pointer;
      }
      .topicTitle {
        margin-left: 20px;
        font-size: 20px;
        font-family: 'Nunito';
        font-weight: 700;
        line-height: 30px;
        color: #000;
      }
    }
  }
}
