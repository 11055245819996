.header_page {
  background-color: $header_bg;
  position: relative;
  overflow: hidden;
  .circle_contai {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffe267;
    height: 232px;
    width: 232px;
    border-radius: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header_logo_pombeebee {
      width: 170px;
      height: 60px;
      flex-shrink: 0;
    }
  }
}
