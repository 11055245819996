// .account-pages {
.date-picker {
  width: 100%;
  height: 38px;
  border: 1px solid #e6ebf5;
  padding-left: 16px;
  background-color: rgba(230, 235, 245, 0.25);
  color: rgb(73, 80, 87);
}

.date-picker-custom {
  width: 100%;
  height: 43px;
  border: 1px solid #e6ebf5;
  padding-left: 16px;
  background-color: rgba(230, 235, 245, 0.25);
  color: rgb(73, 80, 87);
}

.invalid-feedback-custom {
  display: block;
}
.account-pages .overflow-y {
  height: 100vh;
  overflow-y: auto;
}
// }
