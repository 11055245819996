//Import Icon css
@import './icons.scss';

//light mode
@import './bootstrap.scss';
@import './app.scss';

//rtl mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

//dark mode
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

.nav-item {
  cursor: pointer;
}

.dropdown {
  cursor: pointer;
}

.slick-slide {
  margin-left: 8px;
  margin-right: 8px;
}

.slick-arrow {
  display: none;
}

.emoji-mart {
  border: none !important;
}

.emoji-mart-preview {
  display: none !important;
}

.emoji-mart-bar {
  border: none !important;
}

.alice-carousel__stage-item {
  margin-left: 8px;
  margin-right: 8px;
  width: 71px !important;
}

.input-file {
  label {
    margin-bottom: 0;
  }
  input[type='file'] {
    display: none;
  }
}

.emoji-mart-light {
  background-color: $card-bg !important;
}

.emoji-mart-search {
  input {
    background-color: $card-bg !important;
    border-color: $card-border-color !important;
  }
}

.emoji-mart-category-label {
  span {
    background-color: $card-bg !important;
    color: $gray-700;
  }
}

.emoji-mart-category {
  .emoji-mart-emoji {
    &:hover {
      background-color: $gray-300 !important;
    }

    &::before {
      background-color: $gray-300 !important;
      border-radius: 100% !important;
    }
  }
}
.w-70 {
  width: 73% !important;
}

.ctext-wrap-content {
  animation: flyIn 0.6s ease-in-out;
}

.blank-div {
  width: 36px;
  height: 36px;
}

.profile-user-dropdown {
  .dropdown-item {
    &.active {
      color: $white;
      text-decoration: none;
      background-color: $primary;
    }
  }
}

.checkbox-custom {
  position: relative;
  border: 2px solid #000;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
}

.checkbox-custom:hover {
  opacity: 1;
}

.checkbox-custom:checked {
  background-color: $yelow-700;
  opacity: 1;
}

.checkbox-custom:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #edeeee;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.radio-custom {
  position: relative;
  border: 2px solid #000;
  border-radius: 15px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
}

.radio-custom:hover {
  opacity: 1;
}

.radio-custom:checked {
  background-color: $yelow-700;
  opacity: 1;
}

.radio-custom:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #edeeee;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.required {
  color: red;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(212, 212, 212);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

.modal-content {
  border: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

// text style
.subtitle1 {
  font-size: 16px;
  font-weight: 700;
}

.subtitle2 {
  font-size: 14px;
  font-weight: 700;
}

.body1 {
  font-size: 16px;
  font-weight: 400;
}

.body2 {
  font-size: 14px;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  font-weight: 500;
  color: #848484;
}

.action-form-button {
  color: #000;
  border-radius: 20px;
  box-shadow: 0px 6px 10px 0px #00000026;
}

.action-form-button:hover:not([disabled]) {
  background-color: #6b4701;
}

.action-form-button.disabled,
.action-form-button:disabled {
  color: #fff;
  background-color: #a3b2c7;
  border-color: #a3b2c7;
}

.input-custom:-webkit-autofill,
.input-custom:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

//Responsive
@media screen and (min-width: 768px) {
  .main-logo-screen-small {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .left-background {
    display: none;
  }
}

#viewerBackdrop > {
  button {
    display: none;
  }
}

//image viewer custom
#viewerBackdrop {
  .content_e296pg {
    width: 50%;
    margin-bottom: 0 !important;

    .header_j7zky3 {
      display: flex;
      height: 40px;
      width: 40px;
      border: 1px solid white;
      align-items: center;
      border-radius: 50%;
      background: black;
      position: absolute;
      right: -8px;
      top: -8px;

      .close_1tcvdj4 {
        margin-right: 0;
        padding: 8px;
      }
    }
  }
  .img_pc9x2k-o_O-imgLoaded_9vayoh {
    border-radius: 40px;
    width: 100%;
    object-fit: cover;
    display: block;
  }
}
