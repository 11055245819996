.detailCourseContai {
  height: calc(100vh - 200px);

  //start bodycourse
  .bodycourse {
    height: 100%;
    width: 100%;
    padding-right: 0.75rem;
  }
  //end body course

  //start content course
  .contentCourse {
    padding-top: 1rem;
    height: 100%;
    overflow-y: auto;
    @media (max-width: 991.98px) {
      height: fit-content;
    }
    .itemCourseContaiTop {
      padding: 16px 3px;
      border-radius: 20px;
      box-shadow: $boxShadow;
      font-size: 14px;
      margin: 0px 12px 0px 16px;
      .course-rate {
        img {
          height: 16px;
        }
        .cout_rate {
          font-size: 14px;
          margin-top: 1px;
        }
      }
      .nameCourse {
        font-size: 38px;
      }
      .desCourse {
        padding: 0px 17px 17px 17px;
        opacity: 0.7;
      }
      .contentCategory {
        border: unset;
      }
      .itemInfo {
        position: relative;
        .rightInfo {
          position: absolute;
          left: 130px;
        }
      }
    }

    //start curriculum
    .curriculum-wrapper {
      margin: 40px 10px 0px 16px;
      .wrapperGeneral {
        display: flex;
        justify-content: space-between;
        @media (max-width: 650px) {
          flex-direction: column;
        }
        .topRightCurri {
          display: flex;
          justify-content: space-between;
          .topRightItem {
            margin-left: 20px;
            img {
              width: 18px;
              margin-right: 10px;
            }
            span {
              font-size: 14px;
            }
          }
        }
        h4 {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
    //end curriculum

    //start requirement
    .requirement {
      margin: 40px 10px 18px 15px;
      h4 {
        font-size: 24px;
        font-weight: 700;
      }
      .contentRequi {
        font-size: 14px;
        font-weight: 400;
        white-space: pre-line;
      }
      .see-more {
        color: $header-bg;
        font-size: 14px;
        cursor: pointer;
        img {
          margin-left: 10px;
        }
      }
      .contentRequirement {
        padding: 20px;
        border-radius: 20px;
        box-shadow: $boxShadow;
      }
    }
    //end requirement

    //start rating
    .courseRateWrapper {
      margin-top: 40px;
      h4.courseRateTitle {
        font-size: 24px;
        font-weight: 700;
        margin-left: 30px;
      }
      .listRate {
        margin: 0px 25px;
        .rateItem-contai {
          padding: 5px;
        }
      }
      .itemRate {
        padding: 20px;
        border-radius: 20px;
        box-shadow: $boxShadow;
        .headerTeacher {
          position: relative;
          align-items: flex-end;
          .img-headerTecher {
            height: 41px;
            width: 41px;
            border-radius: 50%;
          }
          .infoTeacher {
            h5,
            li {
              font-size: 14px;
              margin-bottom: unset;
            }
          }
          .rightIon {
            position: absolute;
            top: 10px;
            right: 0px;
          }
        }
      }
    }
    //end rating

    //start other couse
    .otherContaiCourse {
      margin-top: 20px;
      margin-bottom: 10px;
      h4.titleOther {
        font-size: 24px;
        font-weight: 700;
        margin-left: 30px;
      }
      .courseItemOther {
        padding: 12px;
      }
      .itemCourseContai {
        padding: unset;
        cursor: pointer;
        .contaiContent {
          border-radius: 20px;
          box-shadow: $boxShadow;
          .contentCategory {
            padding: 30px 0px;
          }
        }
        .course-img {
          overflow-y: hidden;
        }
        img {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        .nameCourse {
          font-size: 20px;
        }
      }
      .listCourse {
        position: relative;
        margin: 0px 20px 0px 30px;
      }
      .slideIcon {
        border: unset;
        padding: 17px;
        border-radius: 50%;
        position: absolute;
        background-color: #fff;
        box-shadow: $boxShadow;
      }
      .preIcon {
        top: 45%;
        transform: translateX(-25%);
      }
      .nextIcon {
        top: 45%;
        left: 100%;
        transform: translateX(-75%);
      }
    }

    .showAllReview {
      margin-left: 30px;
    }
    .showAllReview.disabled {
      cursor: default;
      opacity: 0.5;
      display: none;
    }
    .showAllReview.disabled:hover {
      background-color: $header-bg;
      color: #000 !important;
    }
    //end other course
    .headerTeacher {
      gap: 10px;
      align-items: flex-end;
      padding-bottom: 20px;
      margin-bottom: 15px;
      border-bottom: 0.5px solid #a3b2c7;
      .img-headerTecher {
        height: 80px;
        width: 80px;
        border-radius: 50%;
      }
      .list-info {
        margin: unset;
        padding: unset;
        .item-info {
          display: inline;
          list-style-type: none;
          padding: 0px 15px;
          border-right: 0.5px solid #a3b2c7;
          font-size: 14px;
          font-weight: 400;
        }
        li:last-child {
          border-right: none;
        }
        li:first-child {
          padding-left: 0px;
        }
      }
    }
  }
  //end content course

  //start right course
  .detailRightCourse {
    padding: 0px 10px 10px 10px;
    height: 100%;
    overflow-y: auto;
    @media (max-width: 991.98px) {
      padding: 0px 20px 30px 30px;
      overflow-y: unset;
      height: fit-content;
    }
    .rightCourse-wrapper {
      // padding: 20px;
      border-radius: 20px;
      box-shadow: $boxShadow;
      .topRightCourse {
        width: 100%;
        .course-img {
          width: 100%;
          overflow-y: hidden;
        }
        img {
          width: 100%;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
        }
        h4 {
          font-size: 24px;
          font-weight: 700;
          margin: 15px 0px 5px 0px;
        }
        h4,
        h5 {
          margin: unset;
          line-height: 24px;
        }
        h4 {
          margin-right: 20px;
        }
        h3 {
          font-size: 18px;
        }
        .submitTop {
          width: 100%;
          font-size: 16px;
          margin: 10px 0px;
          max-width: 350px;
        }
        .topUnbeebux {
          padding: 0px 20px;
        }
      }
      .wrapperGeneral {
        padding: 20px;
        h4 {
          font-size: 18px;
        }
        .topRightItem {
          padding: 5px 0px;
          img {
            margin-right: 10px;
          }
          span {
            font-size: 16px;
          }
        }
      }
      .shareCourse {
        padding: 5px 20px 10px 20px;
        border-top: 0.5px solid #a3b2c7;
        align-items: center;
        .listImageShare {
          .border_img {
            max-width: 40px;
            max-height: 40px;
            padding: 8px;
            border-radius: 50%;
            box-shadow: $boxShadow;
            margin-left: 15px;
            cursor: pointer;
            img {
              height: 24px;
            }
          }
        }
        h4 {
          font-size: 18px;
          margin-top: 3px;
          font-weight: 700;
        }
      }
    }
  }
  //end right course
}
//start header title
.headerCourse {
  padding: 10px 30px;
  span {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.5;
  }
  .courseTitle {
    color: $header-bg;
    opacity: 1;
  }
}
//end header title

//top-right curri

//table curri
.tableCurri {
  background-color: $option-color;
  border-radius: 20px;
  width: 100%;
  height: fit-content;
  box-shadow: $boxShadow;
  tr {
    padding: 0px 20px;
    cursor: pointer;
  }
  tr:first-child {
    margin-top: 15px;
  }
  tr:last-child {
    margin-bottom: 15px;
  }
  td {
    border: unset;
    font-size: 16px;
    font-weight: 700;
  }
  .tableChild {
    td {
      font-size: 14px;
      font-weight: 400;
      .preview-video {
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .tableChild.disable {
    opacity: 0.6;
    cursor: default;
    button {
      cursor: default;
    }
    button:hover {
      background-color: $header-bg;
    }
  }
  .activeVideo td:nth-child(2) {
    color: $header-bg;
  }
}

.modalContaiGeneral {
  .modal-content {
    width: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    padding: 0px 10px;
    .modal-header {
      border-bottom: unset;
    }
    .modal-body {
      width: fit-content;
      .body_modal_general {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h5 {
          font-size: 16px;
          font-weight: 400;
        }
        .img-modal-body {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
        .action-modal {
          display: flex;
          justify-content: center;
          margin: 20px 0px;
          gap: 10px;
          button {
            width: 100px;
          }
          .unset_btn {
            background-color: $header-bg !important;
          }
          .unset_btn:hover {
            background-color: #6b4701 !important;
            color: $white !important;
            span,
            div {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.modalContaiRate {
  max-width: 800px;
  .infoTeacher.isRepName {
    background-color: red;
    height: 100%;
  }
  .modal-content {
    border-radius: 40px;
    max-height: 90vh;
  }
  .modal-header {
    background: $option-color;
    padding-left: 20px;
    padding-right: 20px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    h5 {
      text-align: center;
      width: 100%;
      font-size: 20px;
    }
  }
  .modal-body {
    max-height: calc(90vh - 65px);
    height: fit-content;
    .contai-body-modal {
      display: flex;
      .leftBody {
        min-width: 300px;
      }
    }
    .contaiListStar {
      margin-bottom: 10px;
      span {
        font-size: 14px;
        font-size: 400;
      }
      img {
        margin-right: 5px;
        width: 16px;
        margin-bottom: 4px;
      }
    }
    .rightBody {
      height: calc(90vh - 110px);
      height: fit-content;
      overflow-y: auto;
      min-width: 454px;
      .contaiListStar {
        margin-bottom: 0px;
        img {
          width: 14px;
          margin-bottom: 0px;
        }
      }
    }
    .itemRate {
      padding: 20px;
      border-radius: 20px;
      box-shadow: $boxShadow;
      position: relative;
      .read_more {
        color: $header-bg;
        font-size: 12px;
        width: 100%;
        text-align: center;
        cursor: pointer;
      }
      .textWrite-contai {
        textarea {
          width: 100%;
          outline: none;
          border-radius: 5px;
          padding: 5px;
          border: 1px solid #bfbfbf;
        }
        .submitReply {
          font-size: 12px;
          padding: unset;
          padding: 5px 10px;
        }
        .replySpan {
          position: absolute;
          bottom: 20px;
          right: 20px;
          color: $header-bg;
          cursor: pointer;
          font-size: 14px;
        }
        .replySpan:hover {
          color: #ffde79;
        }
      }
      .headerTeacher.isRepName {
        align-items: center !important;
      }
      .headerTeacher {
        position: relative;
        align-items: flex-end;
        gap: 10px;
        margin-bottom: 10px;
        .img-headerTecher {
          height: 41px;
          width: 41px;
          border-radius: 50%;
        }
        .infoTeacher {
          ul {
            margin: unset;
            padding: unset;
          }
          h5,
          li {
            font-size: 14px;
            margin-bottom: unset;
            list-style-type: none;
          }
        }
        .rightIon {
          position: absolute;
          top: 10px;
          right: 0px;
        }
      }
    }
  }
}

//modal write review
.modalWriteReview {
  .modal-header {
    h5 {
      width: 100%;
      text-align: center;
      font-size: 700;
    }
  }
  .name-note {
    margin-left: 20px;
    h5 {
      font-size: 16px;
      font-weight: 600 !important;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .user-img {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  .listStar-review {
    ul {
      margin: unset;
      padding: unset;
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
    li {
      list-style-type: none;
      display: inline;
      img {
        height: 36px;
        width: 36px;
        margin: 0px 10px;
      }
    }
  }
  .textWrite-contai {
    margin-top: 20px;
    textarea {
      border-radius: 15px;
      padding: 10px;
      width: 100%;
      border: none;
      background-color: $option-color;
      outline: unset;
    }
  }
}
//end modal write review
