//
//  User profile details.scss
//

// User profile details

.user-profile-sidebar {
  height: 100vh;
  background-color: $card-bg;
  display: none;
  min-width: 380px;
  max-width: 380px;

  @media (min-width: 992px) {
    border-left: 4px solid $border-color;
  }

  @media (max-width: 1199.98px) {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }

  @media (max-width: 575.98px) {
    min-width: 100%;
  }
}

.user-profile-desc {
  height: calc(100vh - 300px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 324px);
  }
}

.option_select_group {
  margin-top: 10px;
  list-style-type: none;
  padding: 25px 20px 15px 20px;
  border-radius: 20px;
  background-color: rgba(165, 165, 165, 0.3);
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 180px;
  position: relative;
  .suggested {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(199, 199, 199);
    border-radius: 20px 0px;
    padding: 0px 10px;
  }
  .option_select_item {
    vertical-align: center;
    width: fit-content;
    text-align: start;
    input {
      opacity: 0;
      height: 1px;
      width: 1px;
      display: none;
    }
    label {
      background-color: #fff;
      padding: 5px 15px;
      border-radius: 20px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      white-space: nowrap;
    }
    .longTextLabel {
      white-space: normal;
    }
    label:hover {
      background-color: $header_bg;
    }
    input[type='radio']:checked + label {
      background: $header_bg;
    }
  }
}
