.contai-content-library {
  position: relative;
  .detail_wrapper {
    height: calc(100% - 67px);
    overflow-y: auto;
    .card_wrapper {
      margin-bottom: 20px;
      position: relative;

      .card_left_content > div {
        margin: 4px 0px;
      }

      .card_right_freemium {
        display: flex;
        align-items: flex-end;
        .card_right_freemium_price {
          border-radius: 16px 0px;
          padding: 6px 16px;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .rdw-editor-toolbar {
      visibility: hidden !important;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 17px;
      }
      i {
        font-size: 20px;
        margin-right: 20px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &_name {
      font-weight: 100;
      padding-bottom: 10px;
    }

    &_tab {
      display: flex;
      align-items: center;
      font-weight: 700;
      &_title {
        padding-right: 10px;
      }
    }

    &_posted {
      color: $gray_500;
      font-size: 13px;
      display: flex;
      align-items: center;
      span {
        margin-right: 16px;
      }
      div {
        display: flex;
        align-items: center;
        i {
          margin-right: 2px;
        }
      }
    }

    &_freemium {
      color: $yelow-700;
      border-top: 2px solid #b5ead7;
      border-bottom: 2px solid #b5ead7;
      padding: 5px 0px;
      margin: 10px 0px;
    }
    img {
      max-width: 100%;
    }

    .thumbnail_image {
      padding-bottom: 10px;
      max-width: 100%;
    }
    .thumbnail_video {
      padding-bottom: 10px;
    }

    .button_payment {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      .button_payment_content {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        padding: 12px 56px;
      }

      &_content {
        color: $black;
        border-radius: 30px;
        cursor: pointer;
      }
    }
    .library_title_button {
      display: flex;
      justify-content: space-between;
      .favorite {
        width: 300px;
      }
    }
  }
  .card_wrapper:hover {
    background: $option_color;
    cursor: default;
  }
  .list_action_wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
    button {
      color: #000 !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      padding: 12px 56px;
    }
    button:hover {
      color: $white !important;
    }
  }

  //css audio play
  .audio-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0px 10px;
    audio {
      width: 100%;
    }
    video {
      width: 100%;
    }
  }
}
