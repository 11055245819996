:root {
  --color-primary: #f9c901;
  --sidebar-width: 260px;
  --header-height: 64px;
  --shadow-base: 0px 6px 10px #00000026;
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
}

body {
  color: #000000;
}
/* toastStyles.css */

/* Customize the border-radius for success toast */
.Toastify__toast--success {
  border-radius: 4px !important; /* Change this value to your desired border-radius */
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #ffffff !important;
  background-color: #03c988 !important;
}

.Toastify__toast-icon .Toastify--animate-icon .Toastify__zoom-enter > div {
  color: #ffffff;
}

.Toastify__toast-theme--dark {
  color: var(--toastify-color-light);
}
.ReactOTPInput__input:focus {
  outline: none; /* This removes the default focus outline */
  border: none; /* Set the border to none */
  /* Add any other custom styles you want for the focused cell */
}

.common_button {
  cursor: pointer;
  background: black;
  display: flex;
  border-radius: 24px;
  float: right;
  padding: 12px 20px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #f9c901;
  outline: none;
  font-weight: 700;
  border: none;
}

.common_button:hover {
  color: #fff;
  background-color: #6b4701 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.gap-base {
  gap: 1rem;
}

.gap-m {
  gap: 1.5rem;
}

.gap-l {
  gap: 1.875rem;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-14 {
  margin-top: 0.875rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.flex-1 {
  flex: 1 !important;
}

.questionWrapper {
  justify-content: flex-start;
}

.subtitle {
  color: #000000;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-weight: 700;
}

.text-body {
  color: #000000;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-weight: 400;
}

.title-primary {
  margin-bottom: 1.875rem;
  font-size: 1.25rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: var(--color-primary);
}

.uppercase {
  text-transform: uppercase !important;
}

.required {
  color: #dc3545;
}

.nowrap {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-ex {
  padding: 0px 40px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.payment-status {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.payment-status.paid {
  color: #03c988;
}

.payment-status.pending {
  color: #e76161;
}

.btn {
  height: 2.875rem;
  padding: 0px 1.5rem !important;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700 !important;
  color: #000000;
  border-radius: 1.875rem !important;
  box-shadow: var(--shadow-base);
  border: none;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
  text-decoration: none;
  transition: all 0.3s ease;
  background: #ffffff;
  border: 1px solid #a3b2c7;
}
/*form-control*/
.form-control {
  width: 100%;
  padding: 0.875rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #000000;
  background: #f6f6f6;
  box-shadow: var(--shadow-base);
}

.form-control,
.form-control:focus,
.form-control:focus-visible,
.form-control:hover {
  border: none;
  outline: none;
  background: #f6f6f6;
  box-shadow: var(--shadow-base);
}

input.form-control {
  height: 2.875rem;
  border-radius: 1.5625rem;
}

input.form-control.large {
  height: 3.125rem;
}

textarea.form-control {
  padding: 1rem 1.25rem;
  border-radius: 1.25rem;
  resize: none;
}

.dropdown.form-control {
  height: 2.875rem;
  padding: 0.75 1.25rem;
  border-radius: 1.875rem;
}

.form-control.invalid {
  border: 1px solid #f44336;
}

.form-control.invalid + .validate-message {
  margin-top: 0.25rem;
  font-family: 0.75rem;
  color: #f44336;
}

.form-group + .form-group {
  margin-top: 2.5rem;
}

.form-group.inline {
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.form-group.inline > label {
  margin-bottom: 0;
}

.form-group .option-custom {
  margin: 0;
  min-width: 18.75rem;
  max-width: 18.75rem;
}

.form-group .option-custom.filter {
  min-width: 20rem;
  max-width: 20rem;
}

.form-control-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.form-control-wrapper > i {
  position: absolute;
  z-index: 1;
  right: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.form-control-wrapper > .form-control {
  padding-right: 2.5rem;
}

.form-group > label {
  display: block;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: #000000;
}

.form-group > label:has(.required) {
  display: flex;
  align-items: center;
  gap: 0.125rem;
}

.form-control.outline {
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #a3b2c7;
  border-radius: 0.875rem;
}

input.form-control.outline {
  height: 3.375rem;
}

.form-control.outline {
  transition: all 0.3s ease;
}

.form-ex {
  margin: auto;
  padding-top: 1rem;
  width: 48.825rem;
}

.form-ex .option-custom {
  margin: 0;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  cursor: pointer;
}

.checkbox > input[type='checkbox'] {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.125rem;
  background: #ffffff;
  border: 1px solid #d9e1ec;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  transition: all 0.2s ease;
}

.checkbox > input[type='checkbox']:checked {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.checkbox > input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  box-shadow: inset 1em 1em #ffffff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkbox > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.checkbox > label {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkbox + .checkbox {
  margin-top: 1rem;
}
.checkbox:hover > label {
  color: var(--color-primary);
}

.checkbox:hover > input[type='checkbox'] {
  border-color: var(--color-primary);
}

.search {
  width: 16rem;
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.search i:first-child,
.input-icon i:first-child {
  position: absolute;
  left: 0.875rem;
  font-size: 1.25rem;
}

.input-icon i:last-child,
.search i:last-child {
  position: absolute;
  right: 0.875rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.search > .form-control,
.input-icon > .form-control {
  padding-left: 2.5rem;
  border-radius: 23px;
}

.upload-file {
  position: relative;
  margin-top: 2.5rem;
  width: 8.75rem;
  height: 6.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.875rem;
  background: #f6f6f6;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-file:hover {
  box-shadow: var(--shadow-base);
}

.upload-file > i {
  font-size: 2.5rem;
  color: #000000;
  opacity: 0.5;
}

.upload-file > input[type='file'] {
  display: none;
}

.upload-file > .preview {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.upload-file > .preview > img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 0.875rem;
}

.form-ex > .footer {
  margin-top: 5rem;
  padding-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
  border-top: 0.5px solid #a3b2c7;
}

.form-ex > .footer .btn {
  font-size: 1rem;
  line-height: 1.25rem;
  height: 3.375rem;
  width: 11.625rem;
  text-transform: uppercase;
}

/*Layout*/
.layout-teacher {
  display: grid;
  margin: auto;
  grid-template-columns: var(--sidebar-width) auto;
}

/*Sidebar*/
.layout-teacher .aside {
  width: var(--sidebar-width);
  height: 100vh;
  padding: 1.25rem;
  background: #f6f6f6;
  box-shadow: var(--shadow-base);
}

.logo-ex {
  height: 60px;
  width: 10rem;
}

.logo-ex > img {
  width: 100%;
  height: 100%;
}

.menu {
  width: 100%;
  padding-left: 0;
  margin-top: 3.375rem;
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
}

.menu-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.125rem 1.625rem;
  color: #000000;
  font-size: 1rem;
  background: #ffffff;
  border-radius: 1.25rem;
  border: 0.25rem solid #ffffff;
  transition: all 0.3s ease;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.3s ease;
}

.menu-item:hover {
  box-shadow: var(--shadow-base);
}

.menu-item > a,
.menu-item > a:hover,
.menu-item > a:visited,
.menu-item > a:focus {
  text-decoration: none;
  color: #000000;
}

.menu-item > a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.menu-item.active {
  background: var(--color-primary);
  box-shadow: 0px 6px 10px #00000026;
}

/*Main Content*/
.layout-teacher .main-content {
  position: relative;
  background: #ffffff;
  height: 100vh;
  overflow-y: auto;
}

.main-content:has(.infinite-scroll-component__outerdiv) {
  overflow: hidden;
}

.main-content:has(.infinite-scroll-component__outerdiv) .container-ex {
  padding: 0rem 1.5rem;
}

.main-content:has(.is-overflow .infinite-scroll-component__outerdiv) {
  overflow-x: hidden;
  overflow-y: auto;
}

.main-content:has(.is-overflow .infinite-scroll-component__outerdiv)
  .container-ex {
  padding: 0rem 2.5rem;
}

/*Header*/
.main-content .header {
  height: var(--header-height);
  position: sticky;
  top: 0px;
  z-index: 2;
  background: #ffffff;
  border-bottom: 0.5px solid #a3b2c7;
}

.main-content .header div {
  height: 100%;
}

.header .logout {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/*Breadcrumb*/
.pbb-breadcrumbs {
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.pbb-breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pbb-breadcrumb__link {
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  cursor: pointer;
}

a.pbb-breadcrumb__link:active,
a.pbb-breadcrumb__link:hover,
a.pbb-breadcrumb__link:visited,
a.pbb-breadcrumb__link:focus {
  text-decoration: none;
  color: var(--color-primary);
}

.pbb-breadcrumb__link--current {
  color: #000000;
  opacity: 0.5;
}

.pbb-breadcrumb__separator {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
  opacity: 0.5;
}

.content {
  margin: auto;
  max-width: 1440px;
  /* min-width: 1024px; */
}

/*Content*/
.content .page-title {
  width: 100%;
  padding: 1.875rem 2.5rem 0rem 2.5rem;
  position: sticky;
  top: calc(var(--header-height));
  z-index: 2;
  background: #ffffff;
}

.page-title .inner-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title .bottom-action {
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title .bottom-action .input-icon {
  width: 18.75rem;
}

.page-title .title {
  margin-bottom: 0px;
  font-size: 2.375rem;
  line-height: 3rem;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.page-content {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.courses {
  width: 100%;
  height: calc(100vh - 232px);
  overflow: auto;
  padding-bottom: 2.5rem;
}

.infinite-scroll-component {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 0rem 1rem 2rem 1rem;
}

/*Course*/
.course {
  min-height: 12.8125rem;
  padding: 2.5rem 1.25rem 1.25rem 1.25rem;
  background: #ffffff;
  border-radius: 1.25rem;
  box-shadow: var(--shadow-base);
  position: relative;
  display: grid;
  grid-template-columns: 12.8125rem calc(100% - 12.8125rem);
  gap: 1.5rem;
  transition: all 0.3s ease;
}

.course:hover {
  background: rgba(249, 201, 1, 0.2);
}

.course + .course {
  margin-top: 1.5rem;
}

.course__action {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #f6f6f6 !important;
  padding: 0 !important;
  box-shadow: none;
}

.course__action--wrapper {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

.course__action__menu {
  min-width: 15.3125rem;
  padding: 1.375rem 0rem;
  border-radius: 1.25rem;
  background: #ffffff;
  box-shadow: var(--shadow-base);
}

.menu__menu-item {
  height: 2.75rem;
  width: 100%;
  padding: 0rem 1.375rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu__menu-item > i {
  font-size: 1.25rem;
  color: #000000;
  opacity: 0.5;
}

.menu__menu-item > span {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #000000;
}

.menu__menu-item:hover {
  background: #f6f6f6;
}

.course__picture {
  height: 100%;
  display: flex;
  align-items: center;
}

.course__picture > img {
  width: 12.8125rem;
  height: 10rem;
  display: block;
}

.course__info {
  width: 100%;
}

.course__name {
  margin-bottom: 0.875rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: #000000;
  width: calc(100% - 4rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.course__stars {
  display: flex;
  gap: 0.5rem;
}

.course__stars .star > i {
  font-size: 1.125rem;
  color: #000000;
  opacity: 0.2;
}

.star.voted > i {
  color: var(--color-primary);
  opacity: 1;
}

.course__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.course__tags .tag {
  height: 1.875rem;
  padding: 0rem 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 700;
  border-radius: 1.75rem;
  border: 1px solid;
}

.course__tags .tag.ielts {
  color: #025464;
  border: 1px solid #025464;
}

.course__tags .tag.writing {
  color: #e57c23;
  border: 1px solid #e57c23;
}

.course__count {
  display: flex;
  align-items: center;
  gap: 0.8125rem;
}

.course__count .label {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #000000;
}

.course__count .value {
  font-size: 0.875rem;
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: #000000;
}

.course.draft .course__count .value.status {
  color: var(--color-primary);
  text-transform: capitalize;
}

.course.hidden .course__count .value.status {
  color: #e76161;
}

.course.public .course__count .value.status {
  color: #03c988;
}

.section {
  position: relative;
  margin-bottom: 1.875rem;
  padding: 1.5rem 1.875rem 1.875rem 1.875rem;
  border-radius: 1.25rem;
  background: #f6f6f6;
  box-shadow: var(--shadow-base);
}

.section > .section__content {
  display: flex;
  gap: 1.875rem;
}

.section > .section__content > label {
  height: 2.875rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: #000000;
  white-space: nowrap;
  text-transform: uppercase;
}

.section .form-control {
  background: #ffffff;
  box-shadow: unset;
}

.section > .footer {
  margin-top: 2.5rem;
  padding-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
  border-top: 0.5px solid #a3b2c7;
}

.section > .footer .btn {
  font-size: 1rem;
  line-height: 1.25rem;
  height: 2.5rem;
  width: 11.625rem;
  text-transform: uppercase;
}

.section > .footer .btn {
  background: #f44336;
  border: 1px solid #f44336;
}

.collape-toggle {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sort-point {
  margin-left: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.section .collape-toggle > i {
  font-size: 1.75rem;
}

.section .sort-point > i {
  font-size: 1.5rem;
}

.section .collape-toggle.collaped {
  transform: rotate(180deg);
}

.section .lecture {
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
}

.lecture {
  padding: 1.4375rem 1.875rem;
  border-radius: 1rem;
  box-shadow: var(--shadow-base);
  background: rgba(0, 0, 0, 0.02);
}

.lecture .lecture-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lecture .lecture-header-left {
  display: flex;
  align-items: center;
}

.lecture > label {
  margin-bottom: 1.4375rem;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.lecture .footer {
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
}

.lecture .footer .btn {
  font-size: 1rem;
  line-height: 1.25rem;
  height: 2.5rem;
  min-width: 12.125rem;
  text-transform: uppercase;
}

.lecture .btn-add-video > input {
  display: none;
}

.lecture > .footer .btn:first-child {
  border: 1px solid #a3b2c7;
}

.lecture > .footer .btn:last-child {
  background: #f44336;
  border: 1px solid #f44336;
}

.section .footer {
  margin-top: 1rem;
}

.section .footer .btn {
  gap: 0.625rem;
  padding: 0rem 1.25rem;
}

.section .footer span {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.video-item {
  width: calc(100% - 1.5rem);
  display: flex;
  align-items: center;
}

.video-item .video-name {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #000000;
  max-width: 30rem;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.video-item i {
  font-size: 1.25rem;
}

.video-item i:first-child {
  margin-right: 0.5rem;
}

.video-item i:last-child {
  margin-left: 1.25rem;
  color: #e76161;
  cursor: pointer;
}

.popup .popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background: #000000;
  opacity: 0.6;
}

.popup .popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: transparent;
  overflow-y: auto;
}

.popup .popup-wrapper .popup-content {
  width: 25.75rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #ffffff;
  border-radius: 2.5rem;
}

.popup.popup-delete .popup-wrapper .popup-content > .icon {
  width: 6.25rem;
  height: 6.25rem;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #f6f6f6;
}

.popup.popup-delete .popup-wrapper .popup-content > .icon > i {
  font-size: 3.125rem;
}

.popup.popup-delete .popup-wrapper .popup-content > h5 {
  margin-bottom: 0.875rem;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.popup.popup-delete .popup-wrapper .popup-content p,
.popup.popup-delete .popup-wrapper .popup-content .message {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.popup-delete .popup-wrapper .popup-content .message > div {
  text-align: center;
}

.popup .popup-wrapper .popup-content > .footer {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
}

.popup .popup-wrapper .popup-content > .footer .btn {
  font-size: 1rem;
  line-height: 1.25rem;
  height: 3rem;
  text-transform: uppercase;
  flex: 1;
}

.popup.popup-delete .popup-wrapper .popup-content > .footer .btn-delete {
  background: #f44336;
  border: 1px solid #f44336;
}

.popup.popup-delete .popup-wrapper .popup-content > .footer .btn {
  width: 9.75rem;
}

.scroll-container {
  width: 100%;
  height: calc(100vh - 232px);
  overflow: auto;
}

.medias {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}
@media screen and (max-width: 1200px) {
  .medias {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .medias {
    grid-template-columns: repeat(2, 1fr);
  }
}

.medias .infinite-scroll-component {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 1rem 1rem 2rem 1rem;
}

.medias .media {
  width: 100%;
  height: 12.5rem;
  border-radius: 1.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.medias .media:hover {
  box-shadow: var(--shadow-base);
}

.popup.popup-basic .popup-wrapper .popup-content {
  width: 32.5rem;
  padding: 0;
}

.popup.popup-basic .popup-content .popup-header {
  position: relative;
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  border-bottom: 0.5px solid #a3b2c7;
  border-radius: 2.5rem 2.5rem 0rem 0rem;
}

.popup.popup-basic .popup-content .popup-header > h5 {
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.popup.popup-basic .popup-content .popup-header > i {
  position: absolute;
  right: 1.5rem;
}

.popup.popup-basic .popup-wrapper .popup-content > .popup-body {
  width: 100%;
  padding: 1.375rem 2.5rem 0rem 2.5rem;
}

.popup.popup-basic .popup-wrapper .popup-content > .footer {
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  gap: 1.75rem;
}

.popup.popup-basic .preview {
  margin-bottom: 1.375rem;
  width: 100%;
  height: 16rem;
  display: block;
  border-radius: 1rem;
  object-fit: contain;
}

.popup-wrapper .popup-content .close,
.popup-content .popup-header > i {
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 30px;
  z-index: 1;
  cursor: pointer;
}

.copy-control {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.copy-control > .form-control,
.media-info .form-control {
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #a3b2c7;
  border-radius: 0.875rem;
}

.media-info input.form-control {
  height: 3.375rem;
}

.media-info .form-control {
  transition: all 0.3s ease;
}

.media-info .form-control:hover {
  border-color: var(--color-primary);
}

.copy-control > i {
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy-control > i:active {
  color: var(--color-primary);
}

.upload-zone .preview {
  cursor: pointer;
}

.upload-zone > input {
  display: none;
}

.table-container {
  position: relative;
}

.table-container.scroll-container {
  height: calc(100vh - 296px);
}

.sticky-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0rem 1.25rem;
}

.sticky-table thead th {
  z-index: 1;
  background: #ffffff;
  padding: 0rem 0.625rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.sticky-table tbody tr {
  height: 4.75rem;
  box-shadow: var(--shadow-base);
  border-radius: 1.25rem;
  background: #f6f6f6;
  transition: all 0.3s ease;
}

.sticky-table tbody tr:hover {
  background: rgba(249, 201, 1, 0.2);
}

.sticky-table tbody tr td {
  padding: 0.75rem;
  vertical-align: middle;
}

tr td:first-child {
  border-radius: 0 1.25rem 1.25rem 0;
  -moz-border-radius: 1.25rem 0 0 1.25rem;
  -webkit-border-radius: 1.25rem 0 0 1.25rem;
}

tr td:last-child {
  border-radius: 0 1.25rem 1.25rem 0;
  -moz-border-radius: 0 1.25rem 1.25rem 0;
  -webkit-border-radius: 0 1.25rem 1.25rem 0;
}

.sticky-table tbody tr td > .action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.sticky-table tbody tr td > .action i {
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sticky-table tbody tr td > .action i:last-child {
  color: #f44336;
}

.sticky-table tbody tr td > .action i:hover {
  color: var(--color-primary);
}

.quill {
  background: #f6f6f6;
  border-radius: 1.25rem;
  box-shadow: var(--shadow-base);
}

.ql-toolbar.ql-snow {
  padding: 1rem !important;
  border: 0 !important;
  border-bottom: 0.5px solid #a3b2c7 !important;
}

.ql-container.ql-snow {
  border: 0 !important;
}

.ql-editor {
  min-height: 200px;
  padding: 1.25rem !important;
}

.profile-card {
  margin-bottom: 3.125rem;
  border-radius: 1.25rem;
  background: #ffffff;
  border: 0.5px solid #a3b2c7;
  box-shadow: var(--shadow-base);
}

.profile-card .profile-header {
  padding: 1.125rem 1.87rem;
  background: var(--color-primary);
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.profile-card .profile-header .header-content {
  margin-left: 14.0625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-card .profile-header .header-content > h3 {
  font-size: 2.375rem;
  line-height: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
}

.profile-card .profile-header .header-content > .btn {
  background: #ffffff;
}

.profile-card .body {
  padding: 1.25rem 1.87rem;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.profile-card .body .summay-info {
  position: relative;
  margin-left: 14.0625rem;
}

.profile-card .body .avatar-wrapper {
  position: absolute;
  z-index: 1;
  top: 135px;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  border: 0.5rem solid #f6f6f6;
}

.profile-card .body .avatar {
  width: 100%;
  height: 100%;
  position: relative;
}

.profile-card .body .avatar > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.profile-card .body .avatar .upload-image {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #ffffff;
  border: 2px solid var(--color-primary);
  cursor: pointer;
}

.profile-card .body .avatar .upload-image > i {
  font-size: 1.5rem;
}

.profile-card .body .avatar .upload-image > input {
  display: none;
}

.summary-tag {
  padding: 0.375rem 0.875rem;
  border-radius: 1.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f6f6f6;
}

.tag-container {
  display: flex;
  align-items: center;
  gap: 3.75rem;
}

.tag-container + .tag-container {
  margin-top: 0.75rem;
}

.profile-card .bio {
  margin-top: 3rem;
  padding: 1.5rem 1.375rem;
  border-radius: 1rem;
  background: #f6f6f6;
}

.profile-card .bio > h5 {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: var(--color-primary);
}

.profile-card .bio > p {
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
}

.commission-action {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.commission-action .input-icon {
  width: 18.75rem;
}

.commission-action .input-icon > .form-control {
  cursor: pointer;
}

.commission-total {
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 1.125rem 2rem;
  border-radius: 1.25rem;
  background: var(--color-primary);
}

.commission-total .total-number {
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.commission-total .total-number > label {
  color: #000000;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
}

.commission-total .total-number > h6 {
  margin-bottom: 0;
  color: #000000;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.modal-datepicker.popup.popup-basic .popup-content .popup-header {
  padding-left: 2.5rem;
  justify-content: flex-start;
}

.modal-datepicker .popup-header > h5 {
  text-transform: unset;
}

.modal-datepicker .popup-header .selected-info {
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.modal-datepicker .popup-header .selected-info > h5 {
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}

.popup.modal-datepicker .popup-wrapper .popup-content {
  width: 64.75rem;
}

.modal-datepicker .popup-header .selected-info > span {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #000000;
  font-weight: 700;
}

.popup.modal-datepicker .popup-wrapper .popup-content .footer {
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
  border-top: 0.5px solid #a3b2c7;
}

.popup.modal-datepicker .popup-wrapper .popup-content .footer .btn {
  width: 11.625rem;
  max-width: 11.625rem;
}

.popup.modal-datepicker .popup-wrapper .popup-content .datepicker-section {
  display: grid;
  grid-template-columns: 1fr 1fr 10.75rem;
  gap: 1.25rem;
}

.popup.modal-datepicker .popup-wrapper .popup-content .datepicker-action {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.popup.modal-datepicker
  .popup-wrapper
  .popup-content
  .datepicker-action
  .action-selection {
  height: 2.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0.5px solid #a3b2c7;
  border-radius: 1.25rem;
  background: #ffffff;
  color: #000000;
  transition: all 0.3s ease;
  font-weight: 700;
}

.popup.modal-datepicker
  .popup-wrapper
  .popup-content
  .datepicker-action
  .action-selection:hover {
  box-shadow: var(--shadow-base);
}

.popup.modal-datepicker
  .popup-wrapper
  .popup-content
  .datepicker-action
  .action-selection.selected {
  border-color: var(--color-primary);
  background: var(--color-primary);
  box-shadow: var(--shadow-base);
}

.react-datepicker {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
}

.react-datepicker__month-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__header {
  border-top-right-radius: 19px !important;
  border-top-left-radius: 19px !important;
}

.react-datepicker__current-month {
  padding-left: 1rem;
  text-align: left;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day,
.react-datepicker__day-name {
  flex: 1;
  height: 46px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400 !important;
  line-height: 24px;
  border-radius: 100% !important;
  transition: all 0.2s ease;
}

.react-datepicker__day-names {
  display: flex;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: var(--color-primary);
  box-shadow: var(--shadow-base);
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__navigation--previous {
  right: 24px;
  left: unset;
}

.labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.labels .checkbox {
  margin-top: 0;
}

.upload-files {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
}

.upload-file.item {
  position: relative;
}

.upload-file > .delete-file {
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  z-index: 2;
  background: #f6f6f6;
  opacity: 0.6;
  cursor: pointer;
  display: none;
  border-radius: 0.875rem;
}

.upload-file:hover .delete-file {
  display: flex;
}

.upload-file:hover .delete-file > i {
  font-size: 1.5rem;
}

.comission-data > .table-container.scroll-container {
  height: calc(100vh - 370px);
}

.upload-zone {
  height: 20.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-zone .upload-placeholder > i {
  font-size: 8rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.upload-zone:hover .upload-placeholder > i {
  color: var(--color-primary);
}

.upload-zone .upload-placeholder {
  opacity: 0.4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-zone .upload-placeholder span {
  display: block;
  font-size: 1.5rem;
}

.input-group-custom .form-control {
  box-shadow: none;
}

.video-react {
  padding-top: 12.5rem !important;
  border-radius: 1.25rem;
}

.popup .video-react {
  padding-top: 16rem !important;
}

.video-react .video-react-video {
  border-radius: 1.25rem;
}

.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  cursor: pointer;
}

.dropdown-menu.show {
  transform: translate3d(-24px, 0px, 0px) !important;
}

.highlight {
  background: rgba(249, 201, 1, 0.2);
}

.currency-unit {
  margin-left: 0.125rem;
}

.ql-editor {
  max-height: 300px;
}

.course-price {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.125rem;
  gap: 0.25rem;
}

.course-price > h5 {
  margin-bottom: 0;
}

.select-list {
  max-height: 500px;
  overflow-y: auto;
}

#scrollRating {
  height: 300px;
}
